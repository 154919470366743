import React from "react";
import { ReactComponent as Logo } from '../logo.svg';

function ImageLogo() {
    return (
        <div style={{ width: "90%", height: "auto", display: "inline-block" }}>
            <Logo style={{ width: "80%", height: "auto" }} />
        </div>
    );
}

export default ImageLogo;
