import about from "./assets/images/img1.png";
function About() {
  return (
    <>
      <div className="about-scroll"></div>

      <div className="container about" style={{ marginTop: "10%" }}>
        <div className="row m-5">
          <div className="col-md-6 text-center">
            <img alt="about" src={about} className="img-fluid" width={500} />
          </div>
          <div className="col-md-6 mt-5">
            <h2 className="main-title about-h2">ABOUT US</h2>
            <p className="main-p">
              At Software Strategic Solutions , we are dedicated to crafting innovative
              software solutions that empower businesses to thrive in the
              digital age. With a focus on cutting-edge technologies and a
              commitment to excellence, we offer a comprehensive range of
              services including AI/ML, mobile solutions, digital
              transformation, SaaS applications, and cloud services. Our team of
              experts is passionate about leveraging their expertise to deliver
              tailored solutions that drive efficiency, growth, and success.
              Whether you're looking to enhance your existing systems or embark
              on a new digital journey, we are here to guide you every step of
              the way. Partner with us and experience the future of technology
              today.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
