import React from "react";
import { Link } from "react-scroll";

function Header() {
  const headerStyle = {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#DAEAD8",
  };

  return (
    <header style={headerStyle}>
      <h1
        style={{
          color: "grey",
          fontSize: "clamp(2rem, 5vw, 5rem)", // Responsive font size
          textAlign: "left",
          width: "50%",
        }}
      >
        YOUR SEARCH ENDS HERE LET’S <span style={{ color: "#FC5D56" }}>START</span>{" "}
        BUILDING
      </h1>

      <p
        style={{
          color: "black",
          fontSize: "clamp(1rem, 3vw, 2rem)", // Responsive font size
          textAlign: "left",
          width: "50%",
        }}
      >
        <span style={{color:"#FC5D56"}}>Software Strategic Solutions</span>™ is a full-service software development
        company.
        <br/>
        <Link
          to="contact"
         
        >
        <button style={{ fontSize: "clamp(1rem, 1vw, 1.5rem)", marginTop:"3%", padding: "1.5%", background: "#FC5D56", color: "white", border: "none", borderRadius: "25rem" }}>
    Book a Free Consultation
  </button>
  </Link>
      </p>
      
      
      
     
      
  


      

      <div className="headerbg"></div>
      <div style={{ position: "absolute", bottom: "20px" }}>
        <Link
          to="services"
          style={{
            display: "block",
            textAlign: "center",
            color: "grey",
            fontSize: "24px",
            cursor: "pointer",
          }}
        >
          Scroll Down
        </Link>
        <i
          className="fas fa-chevron-down"
          style={{
            display: "block",
            textAlign: "center",
            color: "grey",
            fontSize: "36px",
          }}
        ></i>
      </div>
    </header>
  );
}

export default Header;
