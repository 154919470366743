import { Link, animateScroll as scroll } from 'react-scroll'
import ImageLogo from './ImageLogo';

function Footer() {

  const currentYear = new Date().getFullYear();
  return (

    <footer className="text-center text-lg-start bg-body-tertiary text-muted pt-3">


      <section className="">
        <div className="container text-center text-md-start mt-2">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <ImageLogo />
              </h6>
              {/* <p>
                Here you can use rows and columns to organize your footer content. Lorem ipsum
                dolor sit amet, consectetur adipisicing elit.
              </p> */}
            </div>


            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-5">
              <h6 className="text-uppercase fw-bold mb-4">
                Social Media
              </h6>

              <p>
                <i className="fab fa-github"></i>
                <a target="_blank" rel="noreferrer" href="https://github.com/software-strategic-solutions" style={{ color: "grey", textDecoration: 0 }}> Github</a>
              </p>
              {/* <p>
                <i className="fab fa-x-twitter"></i>
                <a target="_blank" rel="noreferrer" href="https://twitter.com" style={{ color: "grey", textDecoration: 0 }}> X</a>
              </p> */}
              <p>
                <i className="fab fa-linkedin"></i>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/software-strategic-solutions" style={{ color: "grey", textDecoration: 0 }}> Linkedin</a>
              </p>
              {/* <p>
                <i className="fab fa-instagram"></i>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in" style={{ color: "grey", textDecoration: 0 }}> Instagram</a>
              </p> */}

            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mt-5">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p><i className="fas fa-home me-3"></i><a href="http://maps.google.com/?q=37, Block L Johar Town, Lahore, Punjab 54782" style={{textDecoration:"none", color:"gray", cursor:"pointer"}}> Growth Coworking Space, <br/>37, Block L Johar Town, Lahore, Punjab 54782</a></p>
              {/* <p>
                <i className="fas fa-envelope me-3"></i>
                info@example.com
              </p> */}
              <p><i className="fas fa-phone me-3"></i><a href='tel:+923147520358' style={{textDecoration:"none", color:"gray", cursor:"pointer"}}> 0092 314 75203 8</a></p>
              {/* <p><i className="fas fa-print me-3"></i> + 01 234 567 89</p> */}
            </div>
          </div>
        </div>
      </section>

      <span onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          background: "#DAEAD8",
          borderRadius: "50%",
          padding: "10px",
          cursor: "pointer",
        }}>
        <i className="fas fa-chevron-up"
          style={{
            display: "block",
            textAlign: "right",
            color: "grey",
            fontSize: "36px",
            margin: "2%"
          }}></i></span>

      <div className="text-center p-4" style={{ "background-color": "rgba(0, 0, 0, 0.05);" }}>
        © {currentYear} Copyright:
        <a className="text-reset fw-bold" href="#"> software-strategic-solutions</a>
      </div>
    </footer>

  );
}
export default Footer;
