import React, { useState } from "react";
import { Link as ScrollLink, scroller } from "react-scroll";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate and useLocation
import ImageLogo from "./ImageLogo";

function Navbar() {
  const [isNavOpened, setIsNavOpened] = useState(false);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // For navigation between routes

  const handleToggle = () => {
    setIsNavOpened(!isNavOpened);
  };

  const closeNav = () => {
    setIsNavOpened(false);
  };

  const handleScrollLinkClick = (section) => {
    if (location.pathname !== "/") {
      // Navigate to home page first, then scroll to the section
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(section, {
          smooth: true,
          duration: 1000,
        });
      }, 100); // Slight delay to ensure the page has changed before scrolling
    } else {
      // If already on home page, scroll directly
      scroller.scrollTo(section, {
        smooth: true,
        duration: 1000,
      });
    }
    closeNav(); // Close the navbar after clicking the link
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light fixed-top" style={{backgroundColor:"#DAEAD8"}}>
      <div className="container">
        <h1 className="logo">
          <ScrollLink
            spy={true}
            smooth={true}
            duration={1000}
            to="headerbg"
            style={{ cursor: "pointer" }}
            onClick={closeNav}
          >
            <ImageLogo />
          </ScrollLink>
        </h1>
        <div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleToggle}
            aria-controls="navbarNav"
            aria-expanded={isNavOpened ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavOpened ? "show" : ""}`}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleScrollLinkClick("headerbg")}
                >
                  Home
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleScrollLinkClick("services")}
                >
                  Services
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleScrollLinkClick("about-scroll")}
                >
                  About
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleScrollLinkClick("contact")}
                >
                  Contact
                </span>
              </li>
              <li className="nav-item">
                <Link
                  to="/careers"
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={closeNav}
                >
                  Careers
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
