import React, { useState, useEffect } from 'react';

function Contact() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set the state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="contact pt-5">
        <div className="w-100 p-md-5 p-3">
          <h3
            style={{
              color: "grey",
              fontSize: "clamp(2rem, 3vw, 5rem)",
              textAlign: "left",
              width: "70%",
              paddingLeft: "10%",
            }}
          >
            READY TO FIND OUT IF WE’RE THE{" "}
            <span style={{ color: "#FC5D56" }}>ONES</span> FOR YOU?
          </h3>
          <p
            style={{
              fontSize: "clamp(1rem, 3vw, 2rem)",
              textAlign: "left",
              width: "70%",
              paddingLeft: "10%",
            }}
          >
            Schedule your <span style={{ color: "#FC5D56" }}>FREE</span>,
            20-minute strategy session with us
          </p>

          {/* Google Calendar iframe */}
          <div
            id="calendar-container"
            style={{
              width: "100%",
              paddingLeft: isMobile ? "0%" : "10%",
              paddingRight: isMobile ? "0%" : "10%",
            }}
          >
            <iframe
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1HB6MBKCMu91eLyMDCyyKDutWSZyM4DVbHbKZeG7PZ5kb9JCswah0iGiSe2cDzUhGmsNE3LDex?gv=true"
              style={{
                border: "0",
                width: "100%",
                height: isMobile ? "1410px" : "800px",
                maxWidth: "100%",
              }}
              frameBorder="0"
              title="Google Calendar"
              id="calendar-iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
