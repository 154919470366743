import React from "react";
import Data from "./assets/images/Group.svg";
import AI from "./assets/images/Group1-1.svg";
import Digital from "./assets/images/Custom-Enterprise-Software-Development-1.svg";
import Mobile from "./assets/images/Native-App-Development-for-iOS-and-Android-1.svg";
import Cloud from "./assets/images/Vector-1.svg";
import SaaS from "./assets/images/Group-269-1.svg";

function Services() {
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    // height: '300px',
    backgroundColor: '#DAEAD8',
    transition: 'background-color 0.3s ease',
    overflow: 'hidden'
  };

  const imgStyle = {
    height: '130px',
    width: '100%',
    // objectFit: 'cover',
    padding: '5%',
    marginTop: '5%',
  };

  const cardBodyStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };
  return (
    <>
      <div className="services-scroll"></div>
      <div className="container services" style={{marginTop: "10%" }}>
      <h2 className="main-title text-center">We Help You</h2>
      <p className="text-center">Design, develop, deploy, manage, and maintain software solutions</p>

      <div className="container">
      <div className="row text-center mt-5">
        {[
          { src: Data, alt: 'Data Services', title: 'Data Services' },
          { src: AI, alt: 'AI/ML', title: 'AI/ML' },
          { src: Mobile, alt: 'Mobile Solutions', title: 'Mobile Solutions' },
          { src: Digital, alt: 'Digital Transformation', title: 'Digital Transformation' },
          { src: SaaS, alt: 'SaaS Applications', title: 'SaaS Applications' },
          { src: Cloud, alt: 'Cloud Services', title: 'Cloud Services' }
        ].map((service, index) => (
          <div
            key={index}
            className="col-6 col-md-4 col-lg-2 mb-4"
            style={{ position: 'relative' }}
          >
            <div
              className="card"
              style={cardStyle}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FC5D56'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#DAEAD8'}
            >
              <img src={service.src} alt={service.alt} style={imgStyle} />
              <div className="card-body" style={cardBodyStyle}>
                
              </div>
              
            </div>
            <h5 className="card-title mt-2">{service.title}</h5>
          </div>
        ))}
      </div>
    </div>
    </div>
    </>
  );
}

export default Services;
