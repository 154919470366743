import React from 'react';

function Careers() {
  // Header styles with dynamic margin-top for mobile
  const headerStyle = {
    marginTop: "10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "20px",
  };

  // Card style for job position section
  const cardStyle = {
    width: "80%",
    maxWidth: "600px",
    margin: "20px auto",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    backgroundColor: "#DAEAD8",
    margin: "3%",
  };

  // Section style for core values
  const sectionStyle = {
    padding: "50px 20px",
    backgroundColor: "#DAEAD8",
    textAlign: "center",
    width: "100%",
  };

  // Grid style for core values section
  const coreValuesStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 cards per row in desktop view
    gap: "20px",
    marginTop: "20px",
    maxWidth: "1200px",
    margin: "20px auto", // Centering the grid section
  };

  // Card style for individual core value
  const valueCardStyle = {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#F8F9FA",
  };

  // Inline media query
  const responsiveStyle = `
    @media (max-width: 768px) {
      .header {
        margin-top: 27% !important; /* Increase margin-top by 5% */
      }
      .core-values {
        grid-template-columns: 1fr !important; /* Make core values one per row */
      }
    }
  `;

  return (
    <>
      {/* Inject the responsive styles */}
      <style>{responsiveStyle}</style>

      <div className="header" style={headerStyle}>
        <h1 style={{ color: "grey" }}>Careers</h1>
        <p style={{ color: "black" }}>
          Explore exciting career opportunities with us!
        </p>

        <div style={cardStyle}>
          <h3>No Open Positions</h3>
          <p>
            Currently, there are no open positions. Please keep visiting this
            page for future opportunities!
          </p>
        </div>

        {/* Section for Core Values */}
        <section style={sectionStyle}>
          <h2>Our Core Values</h2>
          <div className="core-values" style={coreValuesStyle}>
            <div style={valueCardStyle}>
              <h4>Integrity</h4>
              <p>We uphold the highest standards of integrity in all our actions.</p>
            </div>
            <div style={valueCardStyle}>
              <h4>Innovation</h4>
              <p>We constantly strive for innovation to meet our clients' needs.</p>
            </div>
            <div style={valueCardStyle}>
              <h4>Teamwork</h4>
              <p>We work together, across boundaries, to meet the needs of our customers.</p>
            </div>
            <div style={valueCardStyle}>
              <h4>Excellence</h4>
              <p>We deliver excellence through continuous improvement.</p>
            </div>
            <div style={valueCardStyle}>
              <h4>Customer Commitment</h4>
              <p>We develop relationships that make a positive difference in our customers' lives.</p>
            </div>
            <div style={valueCardStyle}>
              <h4>Quality</h4>
              <p>We provide outstanding products and unsurpassed service that deliver premium value to our customers.</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Careers;
